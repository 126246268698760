/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { getMesas, excluirMesa } from "../../services/MesaService";
import CadMesaRow from "./CadMesaRow";
import CadMesaModal from "./CadMesaModal/CadMesaModal";
import PesquisaMesa from "./PesquisaMesa";
import { Load } from "../../components/widgets/spinner/Spinner";

function CadMesa() {
  const [pesquisaMesa, setPesquisaMesa] = useState("");
  const [mesas, setMesas] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  const DEFAULT_MESA = {
    descricao: "",
    situacao: "Ativo",
    mesadelivery: "M",
  };

  const [editMesa, setEditMesa] = useState(DEFAULT_MESA);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function onNovaEmpresaClick(event) {
    setEditMesa(DEFAULT_MESA);
  }

  function getMesasCall(token) {
    setLoading(true);
    getMesas(pesquisaMesa, page || 1, 10)
      .then((result) => {
        setMesas(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const history = useNavigate();

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getMesasCall(token);
  }, [page, pesquisaMesa]);

  function onAlterarClick(event) {
    const cod_mesa = event.target.id.replace("alterar", "");
    const mesa = mesas.find((e) => e.cod_mesa == cod_mesa);

    setEditMesa({ ...mesa });
  }

  function onExcluirClick(event) {
    const cod_mesa = event.target.id.replace("excluir", "");

    excluirMesa(cod_mesa, token)
      .then((mesa) => { })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      )
      .finally(() => {
        getMesasCall(token);
      });
  }

  function onModalSubmit(event) {
    getMesasCall(token);
    setEditMesa(DEFAULT_MESA);
  }

  function onPesquisaMesaChange(event) {
    setPesquisaMesa(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Cadastro Mesa</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovaMesa"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalMesa"
                onClick={onNovaEmpresaClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Nova Mesa
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaMesa
                placeholder="Pesquisa mesa"
                onChange={onPesquisaMesaChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          {loading ? (
            <Load />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">Código</th>
                  <th className="border-gray-200">Descrição</th>
                  <th className="border-gray-200">Situação</th>
                  <th className="border-gray-200">Mesa_Delivery</th>
                  <th className="border-gray-200">Ações</th>
                </tr>
              </thead>

              <tbody>
                {mesas.map((mesa) => (
                  <CadMesaRow
                    key={mesa.cod_mesa}
                    data={mesa}
                    onAlterarClick={onAlterarClick}
                    onExcluirClick={onExcluirClick}
                  />
                ))}
              </tbody>
            </table>
          )}

          <Pagination count={count} />
        </div>
      </main>
      <CadMesaModal data={editMesa} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default CadMesa;
