/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { converteData } from "../../components/helpers/helpers";
import "./fechar-pedido/FecharPedidos.scss";
import {
  getClosureOrder,
  updateOrderStatus,
} from "../../components/helpers/Api";

const DetalhesPedidoFechado = ({ ...props }) => {
  const [loading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [closeOrderTable, setCloseOrderTable] = useState({});

  function handleUpdateStatus(situacao) {
    let data = {
      situacao,
      cod_mesa: props.data.cod_mesa,
    };
    setLoading(true);
    if (situacao) {
      updateOrderStatus(props.data.cod_pedidomesa, data)
        .then((res) => {
          props.setFalseDetailView();
          props.handleRequest();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function handleGetClosureOrder() {
    getClosureOrder(props?.data?.cod_pedidomesa)
      .then((res) => {
        console.log(res);
        setCloseOrderTable(res.data.fechaPedidoMesa);
        setPaymentMethods(res.data.fechaMesaPgto);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  useEffect(() => {
    handleGetClosureOrder();
  }, [props?.data?.cod_pedidomesa]);
  return (
    <main className="close-order">
      <section className="section-one">
        <p className="title">Detalhes do Pedido</p>
        <button onClick={() => props.setFalseDetailView()} className="save">
          Voltar
        </button>
      </section>
      <section className="section-two">
        <div className="aside-left">
          <div className="order-box">
            <p htmlFor="">Cliente {props.data.nome_cliente}</p>
            <p htmlFor="">
              Fechamento Mesa: {converteData(props.data.datahorafechamento)}
            </p>
          </div>
          <div className="input-box">
            <label htmlFor="" className="form-label m-2">
              Valor Total
              <input
                type="number"
                disabled
                value={props?.data?.valor_total?.toFixed(2)}
                className="form-control"
              />
            </label>
            <label htmlFor="" className="form-label w-50 m-2">
              Situação
              <select name="" id="" className="form-select" disabled>
                <option value="">{props.data.situacao}</option>
                <option value="">{props.data.situacao}</option>
                <option value="">{props.data.situacao}</option>
                <option value="">{props.data.situacao}</option>
              </select>
            </label>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th className="text-center">Descrição</th>
                <th className="text-center">Quantidade</th>
                <th className="text-center">Valor Unitário</th>
                <th className="text-center">Valor Total</th>
              </tr>
            </thead>
            <tbody>
              {props?.data?.tb_ipedidomesas?.map((item, index) => (
                <tr key={index}>
                  <td className="text-center">{item.descricao}</td>
                  <td className="text-center">{item.quantidade?.toFixed(2)}</td>
                  <td className="text-center">
                    {item.valor_unitario?.toFixed(2)}
                  </td>
                  <td className="text-center">
                    {item.valor_total?.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="aside-right">
          <label htmlFor="" className="discount form-label w-100">
            Desconto:
            <input
              type="text"
              className="form-control"
              disabled
              value={closeOrderTable?.desconto?.toFixed(2)}
            />
          </label>
          <table className="table">
            <thead>
              <tr>
                <th>Descrição</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethods.map((item, index) => (
                <tr key={item.cod_form_pgto}>
                  <td>{item.descricao}</td>
                  <td>
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={item.valor_total.toFixed(2)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
      <section className="section-three">
        {props.data.situacao === "finalizado" ? (
          <button
            className="save w-100"
            onClick={() => handleUpdateStatus("analisando")}
          >
            {!loading && <span className="indicator-label">Abrir Pedido</span>}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
};

export default DetalhesPedidoFechado;
