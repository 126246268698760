/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import {
  getTributacoes,
  excluirTributacao,
} from "../../services/TributacaoService";
import CadTributacaoRow from "./CadTributacaoRow";
import CadTributacaoModal from "./CadTributacaoModal/CadTributacaoModal";
import PesquisaTributacao from "./PesquisaTributacao";

function CadTributacao() {
  const [pesquisaTributacoes, setPesquisaTributacao] = useState("");
  const [tributacoes, setTributacoes] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();

  const DEFAULT_TRIBUTACAO = {
    descricao: "",
    sit_trib_entrada: "",
    sit_trib_saida: "",
    aliq_trib1_dentro: "",
    aliq_trib1_fora: "",
    cfop_saida: "",
  };

  const [editTributacao, setEditTributacao] = useState(DEFAULT_TRIBUTACAO);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function onNovaTributacaoClick(event) {
    setEditTributacao(DEFAULT_TRIBUTACAO);
  }

  function getTributacoesCall(token) {
    getTributacoes(pesquisaTributacoes, page || 1, token)
      .then((result) => {
        setTributacoes(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }
  const token = localStorage.getItem("token");

  const history = useNavigate();

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getTributacoesCall(token);
  }, [page, pesquisaTributacoes]);

  function onAlterarClick(event) {
    const cod_tributacao = event.target.id.replace("alterar", "");
    const tributacao = tributacoes.find(
      (e) => e.cod_tributacao == cod_tributacao
    );
    setEditTributacao({ ...tributacao });
  }

  function onExcluirClick(event) {
    const cod_tributacao = event.target.id.replace("excluir", "");
    const token = localStorage.getItem("token");
    excluirTributacao(cod_tributacao, token)
      .then((tributacao) => {
        setTimeout(function () {
          history.go(0);
        }, 1000);
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }

  function onModalSubmit(event) {
    setTimeout(function () {
      history.go(0);
    }, 1000);
  }

  function onPesquisaTributacaoChange(event) {
    setPesquisaTributacao(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Cadastro Tributação</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovaTributacao"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalTributacao"
                onClick={onNovaTributacaoClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Nova Tributação
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaTributacao
                placeholder="Pesquisa Tributação"
                onChange={onPesquisaTributacaoChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Código</th>
                <th className="border-gray-200">Descrição</th>
                <th className="border-gray-200">Aliq. Dentro</th>
                <th className="border-gray-200">Aliq. Dentro</th>
                <th className="border-gray-200">sit. Trib. Dentro</th>
                <th className="border-gray-200">sit. Trib. fora</th>
                <th className="border-gray-200">CFOP</th>
                <th className="border-gray-200">Ações</th>
              </tr>
            </thead>
            <tbody>
              {tributacoes.map((tributacao) => (
                <CadTributacaoRow
                  key={tributacao.cod_tributacao}
                  data={tributacao}
                  onAlterarClick={onAlterarClick}
                  onExcluirClick={onExcluirClick}
                />
              ))}
            </tbody>
          </table>
          <Pagination count={count} />
        </div>
      </main>
      <CadTributacaoModal data={editTributacao} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default CadTributacao;
