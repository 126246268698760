import React, { useState, useRef, useContext } from "react";
import { Context } from "../../components/authentication/AuthContext";

function Login() {
  const selectRef = useRef("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const { handleLogin, loading } = useContext(Context);

  function onChangeInput(event) {
    if (event.target.id === "email") {
      setEmail(event.target.value);
    } else {
      setSenha(event.target.value);
    }
  }

  function onChangeSouFornecedor(event) {
    if (selectRef.current.checked) {
    }
  }

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center">
        <div className="container">
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="text-center">
                <img
                  src="/img/favicon/mstile-150x150.png"
                  alt="Sistemac"
                  width={250}
                />
              </div>
              <div className="text-center text-md-center mb-4 mt-md-5">
                <h1 className="mb-0 h4">Entre na plataforma</h1>
              </div>
              <form
                action="#"
                className="mt-4"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="form-group mb-4">
                  <label htmlFor="email">E-mail</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      <svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                        <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                      </svg>
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="examplo@exemplo.com.br"
                      id="email"
                      autoFocus
                      required
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div className="form-group mb-4">
                  <label htmlFor="senha">Senha</label>
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon2">
                      <svg
                        className="icon icon-xs text-gray-600"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </span>
                    <input
                      type="password"
                      placeholder="Senha"
                      className="form-control"
                      id="senha"
                      required
                      onChange={onChangeInput}
                    />
                  </div>
                </div>
                <div>
                  <div className="form-check form-switch mb-2">
                    <input
                      ref={selectRef}
                      className="form-check-input"
                      type="checkbox"
                      id="souFornecedor"
                      onChange={onChangeSouFornecedor}
                    />
                    <label className="form-check-label">Sou fornecedor</label>
                  </div>
                </div>
                <div className="d-grid">
                  <button
                    onClick={() =>
                      handleLogin(email, senha, selectRef.current.checked)
                    }
                    className="btn btn-gray-800"
                  >
                    {!loading && (
                      <span className="indicator-label">Entrar</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Login;
