/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import { deleteOrderTable, getOrders } from "../../components/helpers/Api";
import { useLocation, useNavigate } from "react-router-dom";
import { converteData } from "../../components/helpers/helpers";
import ModalConfirm from "../../components/partials/modals/ModalConfirm";
import DetalhesPedidoFechado from "./DetalhesPedidoFechado";
import { Load } from "../../components/widgets/spinner/Spinner";

const ListarPedidos = ({ ...props }) => {
  const [searchOrder, setSearchOrder] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const defaultLocation = useLocation();
  const [loadingModalConfirm, setLoadingModalConfirm] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [id, setId] = useState("");
  const [detailtView, setDetailView] = useState(false);
  const [codMesa, setCodMesa] = useState("");
  const [closedOrder, setClosedOrder] = useState({});

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }
  const history = useNavigate();

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    handleGetAllOrders();
  }, []);

  function handleGetAllOrders() {
    setLoading(true);
    getOrders(searchOrder, page || 1, 10, "DESC", "todos")
      .then((res) => {
        setData(res.data.data.rows);
        console.log(res.data.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleDeleteOrder() {
    setLoadingModalConfirm(true);
    deleteOrderTable(id, codMesa)
      .then((res) => {
        if (res.status === 200) {
          setOpenModalConfirm(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingModalConfirm(false);
      });
  }
  useEffect(() => {
    handleGetAllOrders();
  }, [page, searchOrder]);
  return (
    <>
      {detailtView ? (
        <DetalhesPedidoFechado
          data={closedOrder}
          handleRequest={() => handleGetAllOrders()}
          setFalseDetailView={() => setDetailView(false)}
        />
      ) : (
        <>
          {loading ? (
            <Load />
          ) : (
            <main className="">
              <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                <div className="d-block mb-4 mb-md-0">
                  <h2 className="h4">Todos os Pedidos</h2>
                </div>
                <div className="btn-toolbar mb-2 mb-md-0">
                  <div className="d-inline-flex align-items-center">
                    <button
                      id="btnNovoParceiro"
                      className="btn btn-primary animate-up-2"
                      data-bs-toggle="modal"
                      data-bs-target="#modalParceiro"
                    >
                      <svg
                        className="icon icon-xs me-2"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      Novo Pedido
                    </button>
                  </div>
                  <div className="btn-group ms-2 ms-lg-3">
                    <input
                      type="text"
                      className="form-control"
                      id="search"
                      placeholder="Digite o nome do cliente"
                      onChange={(e) => setSearchOrder(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="card card-body border-0 shadow table-wrapper table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th className="border-gray-200 text-center">Número</th>
                      <th className="border-gray-200 text-center">Cliente</th>
                      <th className="border-gray-200 text-center">Situação</th>
                      <th className="border-gray-200 text-center">
                        Fechamento
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item) => (
                      <tr
                        key={item.cod_pedidomesa}
                        role="button"
                        onClick={() => [
                          setClosedOrder(item),
                          setDetailView(true),
                        ]}
                      >
                        <td className="text-center">
                          #{item.numero_pedidomesa}
                        </td>
                        <td className="text-center">{item.nome_cliente}</td>
                        <td className="text-center">{item.situacao}</td>
                        <td className="text-center">
                          {item.datahorafechamento
                            ? converteData(item.datahorafechamento)
                            : "Aberto"}
                        </td>
                        {/* <td className="text-center">
                  {
                    <button
                      type="button"
                      className="btn btn-secondary btn-xs ms-2"
                      title="Alterar Produto"
                      data-bs-toggle="modal"
                      data-bs-target="#modalProduto"
                      onClick={() => setLoadingModalConfirm(true)}
                    >
                      <svg
                        className="icon icon-xs"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={1}
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                    </button>
                  }
                  {
                    <button
                      type="button"
                      className="btn btn-danger btn-xs ms-2"
                      title="Delete this Template"
                      onClick={() => setLoadingModalConfirm(true)}
                      onMouseMove={() => [
                        setId(item.cod_pedidomesa),
                        setCodMesa(item.cod_mesa),
                      ]}
                    >
                      <svg
                        className="icon icon-xs"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  }
                </td>{" "} */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <ModalConfirm
                  title="Tem certeza que deseja deletar este endereço?"
                  open={openModalConfirm}
                  setModalCrudFalse={() => setOpenModalConfirm(false)}
                  handleRequest={() => handleDeleteOrder()}
                  loading={loadingModalConfirm}
                  textBack="Cancelar"
                  textSubmit="Deletar"
                />
              </div>
            </main>
          )}
        </>
      )}
    </>
  );
};

export default ListarPedidos;
