/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { getProdutos, excluirProduto } from "../../services/ProdutoService";
import CadProdutoRow from "./CadProdutoRow";
import CadProdutoModal from "./CadProdutoModal/CadProdutoModal";
import PesquisaProduto from "./PesquisaProduto";
import { Load } from "../../components/widgets/spinner/Spinner";

function CadProduto() {
  const [pesquisaProduto, setPesquisaProduto] = useState("");

  const [produtos, setProdutos] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const [loading, setLoading] = useState(false);
  const [pathImage, setPathImage] = useState("");

  const DEFAULT_PRODUTO = {
    descricao: "",
    situacao: "Ativo",
  };
  const token = localStorage.getItem("token");

  const [editProduto, setEditProduto] = useState(DEFAULT_PRODUTO);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function onNovoProdutoClick(event) {
    setEditProduto(DEFAULT_PRODUTO);
  }

  function getProdutosCall(token) {
    setLoading(true);
    getProdutos(pesquisaProduto, page || 1, token, 0)
      .then((result) => {
        setProdutos(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const history = useNavigate();

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getProdutosCall(token);
  }, [page, pesquisaProduto]);

  function onAlterarClick(event) {
    const cod_produto = event.target.id.replace("alterar", "");
    const produto = produtos.find((e) => e.cod_produto == cod_produto);

    setEditProduto({ ...produto });
  }

  function onExcluirClick(event) {
    const cod_produto = event.target.id.replace("excluir", "");
    excluirProduto(cod_produto, token, pathImage)
      .then((produto) => {
        getProdutosCall(token);
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }

  function onModalSubmit(event) {
    getProdutosCall(token);
  }

  function onPesquisaProdutoChange(event) {
    setPesquisaProduto(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Cadastro Produto</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovoProduto"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalProduto"
                onClick={onNovoProdutoClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Novo Produto
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaProduto
                placeholder="Pesquisa mesa"
                onChange={onPesquisaProdutoChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          {loading ? (
            <Load />
          ) : (
            <table className="table table-hover">
              <thead>
                <tr>
                  <th className="border-gray-200">Código</th>
                  <th className="border-gray-200">Imagem</th>
                  <th className="border-gray-200">Descrição</th>
                  <th className="border-gray-200">Código de Barras</th>
                  <th className="border-gray-200">Unidade</th>
                  <th className="border-gray-200">Preço de Venda</th>
                  <th className="border-gray-200">Situação</th>
                  <th className="border-gray-200">Ações</th>
                </tr>
              </thead>
              <tbody>
                {produtos.map((produto) => (
                  <CadProdutoRow
                    key={produto.cod_produto}
                    data={produto}
                    onAlterarClick={onAlterarClick}
                    onExcluirClick={onExcluirClick}
                    setPathImage={(path) => setPathImage(path)}
                  />
                ))}
              </tbody>
            </table>
          )}
          <Pagination count={count} />
        </div>
      </main>
      <CadProdutoModal data={editProduto} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default CadProduto;
