/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { getPreVendas, excluirPreVenda } from "../../services/PreVendaService";
import PreVendaRow from "./PreVendaRow";
import PreVendaModal from "./PreVendaModal/PreVendaModal";
import PesquisaPedido from "./PesquisaPreVenda";
import { formataData } from "../../utils/utils";

function PreVenda() {
  const [PesquisaPreVenda, setPesquisaPreVenda] = useState("");

  const [prevendas, setPreVendas] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const date = new Date();

  const DEFAULT_PREVENDA = {
    situacao: "FECHADO",
    data_fechamento: formataData(date),
    data_emissao: formataData(date),
    cod_pk_funcionario: 1,
    cod_pk_cliente: 1,
    cod_pk_forma_pagto: 6,
    numero_pre_venda: 9999999,
    cod_pk_entregador: 1,
    cod_condicao: 1,
    cod_pk_troca: 1,
    cod_pk_docorig: 1,
    cod_usuario: 1,
    cod_pk_empresa: 1,
    valor_bruto: 0,
    desconto: 0,
    acrescimo: 0,
    valor_liquido: 0,
  };
  const token = localStorage.getItem("token");

  const [editPreVenda, setEditPreVenda] = useState(DEFAULT_PREVENDA);

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  function onNovoPreVendaClick(event) {
    setEditPreVenda(DEFAULT_PREVENDA);
  }

  function getPreVendaCall(token) {
    getPreVendas(PesquisaPreVenda, page || 1, token)
      .then((result) => {
        setPreVendas(result.rows);
        setCount(result.count);
      })
      .catch((err) => {
        console.error(err.response ? err.response.data : err.message);
      });
  }

  const history = useNavigate();

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getPreVendaCall(token);
  }, [page, PesquisaPreVenda]);

  function onAlterarClick(event) {
    const cod_pre_venda = event.target.id.replace("alterar", "");
    const prevenda = prevendas.find((e) => e.cod_pre_venda == cod_pre_venda);

    setEditPreVenda({ ...prevenda });
  }

  function onExcluirClick(event) {
    const cod_pre_venda = event.target.id.replace("excluir", "");
    excluirPreVenda(cod_pre_venda, token)
      .then((prevenda) => {
        history.go(0);
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }

  function onModalSubmit(event) {
    history.go(0);
  }

  function onPesquisaPreVendaChange(event) {
    setPesquisaPreVenda(event.target.value);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Pedidos</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovoProduto"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalPreVenda"
                onClick={onNovoPreVendaClick}
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Novo Pedido
              </button>
            </div>
            <div className="btn-group ms-2 ms-lg-3">
              <PesquisaPedido
                placeholder="Pesquisa mesa"
                onChange={onPesquisaPreVendaChange}
              />
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Numero Pedido</th>
                <th className="border-gray-200">Data Emissão</th>
                <th className="border-gray-200">Nome Cliente</th>
                <th className="border-gray-200">Valor Bruto</th>
                <th className="border-gray-200">Desconto</th>
                <th className="border-gray-200">Valor Liquido</th>
                <th className="border-gray-200">Situação</th>
                <th className="border-gray-200">Ações</th>
              </tr>
            </thead>
            <tbody>
              {prevendas.map((prevenda) => (
                <PreVendaRow
                  key={prevenda.cod_pre_venda}
                  data={prevenda}
                  onAlterarClick={onAlterarClick}
                  onExcluirClick={onExcluirClick}
                />
              ))}
            </tbody>
          </table>
          <Pagination count={count} />
        </div>
      </main>
      <PreVendaModal data={editPreVenda} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default PreVenda;
