import axios from "axios";

const api = process.env.REACT_APP_API_URL;
const token = localStorage.getItem("token");
let codOrg = localStorage.getItem("cod_org");

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  authorization: token,
};
const headersStatics = {
  authorization: token,
};

export function setToken(token, cod_org) {
  headers.authorization = token;
  headersStatics.authorization = token;
  codOrg = cod_org;
}
export function getUser(data) {
  return axios.get(`${api}/menu/identificar/usuario/?number=${data}`);
}
export function createUser(data) {
  return axios.post(`${api}/menu/criar/usuario?cod_org=${codOrg}`, data);
}
export function createOrderMenu(data) {
  return axios.post(`${api}/menu/pedido/criar`, data);
}
export function createAddress(data) {
  return axios.post(`${api}/menu/criar/endereco`, data);
}
export function deleteAddress(id) {
  return axios.delete(`${api}/menu/delete/endereco/?id=${id}`);
}
export function getAddress(data) {
  return axios.get(`${api}/menu/buscar/endereco/?number=${data}`);
}
export function editAddress(id, data) {
  return axios.put(`${api}/menu/editar/endereco/?id=${id}`, data);
}
export function getUrl(url) {
  return axios.get(`${api}/menu/search-url/?url=${url}`);
}
export function getSections(company) {
  return axios.get(`${api}/menu/buscar/secoes/?company=${company}`);
}
export function getOrdersByMenu(cellphone, id) {
  return axios.get(
    `${api}/menu/orders?cellphone=${cellphone}&id=${id}&cod_org=${codOrg}`
  );
}
export function geOrderByIdMenu(id) {
  return axios.get(`${api}/menu/order?id=${id}&cod_org=${codOrg}`);
}
export function getPaymentMethodByMenu() {
  return axios.get(`${api}/menu/meio-pagamento?cod_org=${codOrg}`);
}
export function getOrganizationMenu(org) {
  return axios.get(`${api}/menu/org-data?org=${org}`);
}
export function getServiceCharge() {
  return axios.get(`${api}/menu/service-charge?cod_org=${codOrg}`);
}

//Orders
export function getOrders(order, page, limit, ordernation, all) {
  return axios.get(
    `${api}/order?cod_org=${codOrg}&order=${order}&page=${page}&limit=${limit}&ordernation=${ordernation}&all=${all}`,
    { headers }
  );
}
export function createOrder(data) {
  return axios.post(`${api}/order/create`, data, { headers });
}
export function updateOrderStatus(id, data) {
  return axios.patch(
    `${api}/order/update-status-order/?cod_pedidomesa=${id}`,
    data,
    {
      headers,
    }
  );
}
export function closeOrder(id, data) {
  return axios.put(`${api}/order/close-order/?cod_pedidomesa=${id}`, data, {
    headers,
  });
}
export function getClosureOrder(id) {
  return axios.get(
    `${api}/order/closure/?cod_org=${codOrg}&cod_pedidomesa=${id}`,
    { headers }
  );
}

export function getDeliveryCharge() {
  return axios.get(`${api}/order/delivery-charge/?cod_org=${codOrg}`, {
    headers,
  });
}
export function getAdditionalsByIdProduct(id) {
  return axios.get(`${api}/order/additionals?id=${id}&cod_org=${codOrg}`, {
    headers,
  });
}

///tables
export function getOrderTables(status) {
  return axios.get(
    `${api}/mesa/order-tables?cod_org=${codOrg}&status=${status}`,
    {
      headers,
    }
  );
}
export function deleteOrderTable(id, codMesa, codIpedidoMesa) {
  return axios.delete(
    `${api}/order/delete?id=${id}&cod_mesa=${codMesa}&cod_org=${codOrg}&cod_ipedidomesa=${codIpedidoMesa}`,
    {
      headers,
    }
  );
}
export function updateOrderTable(id, data) {
  return axios.put(`${api}/order/update?id=${id}`, data, {
    headers,
  });
}

//Consumers

export function getConsumers(consumer, page) {
  return axios.get(
    `${api}/consumer?cod_org=${codOrg}&consumer=${consumer}&page=${page}`,
    {
      headers,
    }
  );
}
export function createConsumer(data) {
  return axios.post(`${api}/consumer/create?cod_org=${codOrg}`, data, {
    headers,
  });
}
export function updateConsumer(data, id) {
  return axios.put(`${api}/consumer/update?id=${id}`, data, { headers });
}
export function deleteConsumer(id) {
  return axios.delete(`${api}/consumer/delete?id=${id}`, { headers });
}

//Address

export function createAddressConsumer(data) {
  return axios.post(`${api}/address/create`, data, { headers });
}
export function getAddressConsumer(id) {
  return axios.get(`${api}/address?cod_usuario_consumidor=${id}`, { headers });
}
export function updateAddressConsumer(data, id) {
  return axios.put(`${api}/address/update?cod_endereco=${id}`, data, {
    headers,
  });
}
export function deleteAddressConsumer(id) {
  return axios.delete(`${api}/address/delete?cod_endereco=${id}`, { headers });
}

//Payment Method

export function createPaymentMethod(data) {
  return axios.post(`${api}/meio-pagamento/create`, data, { headers });
}
export function getPaymentMethod(paymentMethod, page) {
  return axios.get(
    `${api}/meio-pagamento?paymentMethod=${paymentMethod}&cod_org=${codOrg}&page=${page}`,
    {
      headers,
    }
  );
}
export function updatePaymentMethod(data, id) {
  return axios.put(`${api}/meio-pagamento/update?cod_forma_pgto=${id}`, data, {
    headers,
  });
}
export function deletePaymentMethod(id) {
  return axios.delete(`${api}/meio-pagamento/delete?cod_forma_pgto=${id}`, {
    headers,
  });
}

//Gerenciamento Menu

export function storeCoverMenu(data) {
  return axios.post(`${api}/menu-admin/criar/capa?cod_org=${codOrg}`, data, {
    headers: headersStatics,
  });
}
export function storeLogoMenu(data) {
  return axios.post(`${api}/menu-admin/criar/logo?cod_org=${codOrg}`, data, {
    headers: headersStatics,
  });
}
export function getDataMenu() {
  return axios.get(`${api}/menu-admin/?cod_org=${codOrg}`, { headers });
}
export function updateCoverMenu(data, oldPath) {
  return axios.put(
    `${api}/menu-admin/atualizar/capa?cod_org=${codOrg}&old_path=${oldPath}`,
    data,
    {
      headers: headersStatics,
    }
  );
}
export function updateLogoMenu(data, oldPath) {
  return axios.put(
    `${api}/menu-admin/atualizar/logo?cod_org=${codOrg}&old_path=${oldPath}`,
    data,
    {
      headers: headersStatics,
    }
  );
}

export function updateDataMenu(data) {
  return axios.put(
    `${api}/menu-admin/atualizar/dados?cod_org=${codOrg}`,
    data,
    { headers }
  );
}
export function updateStatusStore(data) {
  return axios.put(
    `${api}/menu-admin/atualizar/status?cod_org=${codOrg}`,
    data,
    { headers }
  );
}
