/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-computed-key */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import "./CadMeioPagamento.scss";
import AddAdress from "./CadMeioPagamentoModal/CadMeioPagamentoModal";
import {
  deleteAddressConsumer,
  getAddressConsumer,
} from "../../components/helpers/Api";
import { Load } from "../../components/widgets/spinner/Spinner";
import ModalConfirm from "../../components/partials/modals/ModalConfirm";

const MeioPagamentoDetail = ({ ...props }) => {
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [address, setAddress] = useState([]);
  const [oldAddress, setOldAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [codAddress, setCodAddress] = useState("");

  function handleGetAddressConsumer() {
    setLoading(true);
    getAddressConsumer(props.data.cod_usuario_consumidor)
      .then((res) => {
        console.log(res);
        setAddress(res.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleDeleteAddress() {
    deleteAddressConsumer(codAddress)
      .then((res) => {
        if (res.status === 200) {
          setOpenModalConfirm(false);
          handleGetAddressConsumer();
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  }
  useEffect(() => {
    handleGetAddressConsumer();
  }, []);

  return (
    <div className="consumer-detail">
      <main className="main-box">
        <div className="title-box">
          <p className="title">Detalhes do Cliente</p>
          <button className="back" onClick={() => props.setView()}>
            Voltar
          </button>
        </div>
        <div className="content-detail">
          <label htmlFor="">
            Nome: <span className="data-detail">{props.data.nome}</span>
          </label>
          <label htmlFor="">
            Telefone: <span className="data-detail">{props.data.telefone}</span>
          </label>
          <section className="address">
            <div className="title-box">
              <p className="sub-title">Endereços do Cliente</p>
              <button
                className="save"
                onClick={() => setOpenAddressModal(true)}
              >
                Add. Endereço
              </button>
            </div>
            {loading ? (
              <Load />
            ) : (
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th className="border-gray-200 text-center">Cidade</th>
                    <th className="border-gray-200 text-center">Bairro</th>
                    <th className="border-gray-200 text-center">Rua</th>
                    <th className="border-gray-200 text-center">Número</th>
                    <th className="border-gray-200 text-center">Complemento</th>
                    <th className="border-gray-200 text-center">Referência</th>
                    <th className="border-gray-200 text-center">Ações</th>
                  </tr>
                </thead>

                <tbody>
                  <>
                    {address?.map((item) => (
                      <tr key={item.cod_endereco}>
                        <td className="text-center">{item.cidade}</td>
                        <td className="text-center">{item.bairro}</td>
                        <td className="text-center">{item.rua}</td>
                        <td className="text-center">{item.numero}</td>
                        <td className="text-center">{item.complemento}</td>
                        <td className="text-center">{item.referencia}</td>

                        <td className="text-center">
                          {
                            <button
                              id={"alterar" + item.cod_endereco}
                              type="button"
                              className="btn btn-secondary btn-xs ms-2"
                              title="Alterar Parceiro"
                              onClick={() => [
                                setOldAddress(item),
                                setOpenAddressModal(true),
                              ]}
                            >
                              <svg
                                id={"alterar" + item.cod_endereco}
                                className="icon icon-xs"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id={"alterar" + item.cod_endereco}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                />
                              </svg>
                            </button>
                          }
                          {
                            <button
                              id={"excluir" + item.cod_endereco}
                              type="button"
                              className="btn btn-danger btn-xs ms-2"
                              title="Delete this Template"
                              onMouseMove={() =>
                                setCodAddress(item.cod_endereco)
                              }
                              onClick={() => setOpenModalConfirm(true)}
                            >
                              <svg
                                id={"excluir" + item.cod_endereco}
                                className="icon icon-xs"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  id={"excluir" + item.cod_usuario_consumidor}
                                  fillRule="evenodd"
                                  d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          }
                        </td>
                      </tr>
                    ))}
                  </>
                </tbody>
              </table>
            )}
          </section>
        </div>
      </main>
      <AddAdress
        idConsumer={props?.data?.cod_usuario_consumidor}
        telefone={props?.data?.telefone}
        open={openAddressModal}
        setModalAddAddresFalse={() => setOpenAddressModal(false)}
        editData={oldAddress}
        getAddress={() => handleGetAddressConsumer()}
      />
      <ModalConfirm
        open={openModalConfirm}
        title="Deletar Endereço"
        setModalCrudFalse={() => setOpenModalConfirm(false)}
        handleRequest={() => handleDeleteAddress()}
        textBack="Cancelar"
        textSubmit="Deletar"
      />
    </div>
  );
};

export default MeioPagamentoDetail;
