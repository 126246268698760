import React, { createContext, useState } from "react";
import {
  doLogin,
  doLoginFornecedor,
  doLogout,
} from "../../services/AuthService";

import { toast } from "react-toastify";
import { setToken } from "../helpers/Api";

const Context = createContext();
const AuthContext = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("token")
  );
  const [loading, setLoading] = useState(false);
  const [usuarioConsumidor, setUsuarioConsumidor] = useState(
    localStorage.getItem("consumerUser")
      ? JSON.parse(localStorage.getItem("consumerUser"))
      : ""
  );

  const [enderecoUsuarioConsumidor, setEnderecoUsuarioConsumidor] =
    useState("");
  const [searchingUrl, setSearchingUrl] = useState(true);

  function handleLogin(email, senha, isSupplier) {
    setLoading(true);
    if (isSupplier) {
      let cod_org = localStorage.getItem("cod_org");

      doLoginFornecedor(email, senha, cod_org)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("sistemacemail", email);
            localStorage.setItem("sistemacefornecedor", "sim");
            localStorage.setItem("token", res.data.token);
            setToken(res.data.token, cod_org);
            toast.success("Login efetuado com sucesso");
            setTimeout(() => {
              setIsAuthenticated(res.data.token);
            }, 250);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            toast.error(`Usuário ou senha inválidos!`);
          }
        });
    } else {
      let cod_org = localStorage.getItem("cod_org");

      doLogin(email, senha, cod_org)
        .then((res) => {
          if (res.status === 200) {
            console.log(res);
            localStorage.setItem("sistemacemail", email);
            localStorage.setItem("sistemacefornecedor", "nao");
            localStorage.setItem("token", res.data.token);
            setToken(res.data.token, cod_org);
            toast.success("Login efetuado com sucesso");
            setTimeout(() => {
              setIsAuthenticated(res.data.token);
            }, 250);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 401) {
            toast.error(`Usuário ou senha inválidos!`);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }
  function handleLogout() {
    doLogout(localStorage.getItem("token"))
      .then((res) => {
        setIsAuthenticated(null);
        localStorage.removeItem("token");
        localStorage.removeItem("sistemacemail");
        localStorage.removeItem("sistemacefornecedor");
        localStorage.removeItem("cod_org");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <Context.Provider
        value={{
          isAuthenticated,
          handleLogin,
          handleLogout,
          loading,
          usuarioConsumidor,
          setUsuarioConsumidor,
          enderecoUsuarioConsumidor,
          setEnderecoUsuarioConsumidor,
          searchingUrl,
          setSearchingUrl,
        }}
      >
        {children}
      </Context.Provider>
    </>
  );
};

export { AuthContext, Context };
