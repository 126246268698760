/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import "./SelectAdditionalModal.scss";
import { Box, Modal } from "@mui/material";
import { getAdditionalsByIdProduct } from "../../../helpers/Api";
import { Load } from "../../../widgets/spinner/Spinner";

const SelectAdditionalModal = ({ ...props }) => {
  const [additionals, setAdditionals] = useState([]);
  const [data, setData] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [limitSection, setLimitSection] = useState([]);
  let [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    handleGetAdditionals();
  }, [props.id]);

  function handleGetAdditionals() {
    setLoading(true);
    getAdditionalsByIdProduct(props.id)
      .then((res) => {
        if (res.data.product) {
          console.log(res.data.product);
          res.data.product.tb_secaos?.forEach((item) => {
            item.quantidade = 0;
            item?.tb_produtos?.forEach((subItem) => {
              subItem.quantidade = 0;
            });
          });
          setData(res?.data?.product?.tb_secaos);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleAddAdditionals(indexSection, indexProduct, product) {
    setPrice(
      (price += data[indexSection].tb_produtos[indexProduct].preco_venda_vista)
    );
    if (data[indexSection].tb_produtos[indexProduct].quantidade) {
      data[indexSection].tb_produtos[indexProduct].quantidade += 1;
      data[indexSection].quantidade++;
    } else {
      data[indexSection].tb_produtos[indexProduct].quantidade = 1;
      data[indexSection].quantidade++;
    }
    if (data[indexSection].quantidade == data[indexSection].web_quantidade) {
      setLimitSection([...limitSection, indexSection]);
    }
    setData(data);
    setHasIndexies([...hasIndexies, indexProduct]);

    document.getElementById(`product-${product.cod_produto}`).innerText =
      data[indexSection].tb_produtos[indexProduct].quantidade;

    setAdditionals([
      ...additionals,
      {
        amount: data[indexSection].tb_produtos[indexProduct].quantidade,
        name: product.descricao,
        price: product.preco_venda_vista,
        cod_produto: product.cod_produto,
        cod_secao: product.cod_fk_secao,
      },
    ]);
  }

  function handleSubAdditionals(indexSection, indexProduct, product) {
    setPrice(
      (price -= data[indexSection].tb_produtos[indexProduct].preco_venda_vista)
    );
    if (data[indexSection].tb_produtos[indexProduct].quantidade) {
      data[indexSection].tb_produtos[indexProduct].quantidade -= 1;
      data[indexSection].quantidade -= 1;
    } else {
      data[indexSection].tb_produtos[indexProduct].quantidade = 1;
      data[indexSection].quantidade = 1;
    }

    setData(data);
    const filtered = hasIndexies.filter((item) => {
      return item != indexProduct;
    });
    setHasIndexies(filtered);
    const filteredSection = limitSection.filter((item) => {
      return item != indexSection;
    });
    setLimitSection(filteredSection);
    document.getElementById(`product-${product.cod_produto}`).innerText =
      data[indexSection].tb_produtos[indexProduct].quantidade;

    const filter = additionals.filter((item) => {
      return item.cod_produto != product.cod_produto;
    });
    setAdditionals(filter);
  }
  function handleAdditional(indexSection, indexProduct, product) {
    setPrice(
      (price += data[indexSection].tb_produtos[indexProduct].preco_venda_vista)
    );

    additionals.forEach((item) => {
      return item.cod_produto != product.cod_produto &&
        item.cod_secao === product.cod_fk_secao
        ? setPrice((price -= item.preco_venda_vista))
        : "";
    });
    const filtered = additionals.filter((item) => {
      return item.cod_secao != product.cod_fk_secao;
    });
    filtered.push({
      amount: 1,
      name: product.descricao,
      price: product.preco_venda_vista,
      cod_produto: product.cod_produto,
      cod_secao: product.cod_fk_secao,
    });
    setAdditionals(filtered);
  }

  function handleClearFields() {
    props.setFalse();
    setAdditionals([]);
    setHasIndexies([]);
    handleGetAdditionals();
  }

  return (
    <Modal open={props.open}>
      <Box>
        <main class="select-additional-modal">
          <section className="first-section">
            <p className="title"></p>
            <i
              className="fa-solid fa-xmark"
              role="button"
              onClick={() => handleClearFields()}
            ></i>{" "}
          </section>
          <p
            className="sub-title"
            style={{
              textAlign: "center",
              borderBottom: "2px solid #f2f2f2",
            }}
          >
            Adicionais
          </p>
          <section className="second-section">
            <div className="additionals">
              {loading ? (
                <Load />
              ) : (
                <>
                  {data?.length === 0 ? (
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 500,
                        marginTop: 30,
                      }}
                    >
                      Esse produto não tem adicionais
                    </p>
                  ) : (
                    <>
                      {data?.map((item, index) => (
                        <div key={index + 1}>
                          {item.acompanhamento === "S" ? (
                            <>
                              {item.web_quantidade > 1 ? (
                                <section className="additional">
                                  {
                                    <p className="sub-title--additional">
                                      {item.descricao} (Escolha até{" "}
                                      {item.web_quantidade} itens)
                                    </p>
                                  }
                                  {item.tb_produtos.map((subItem, subIndex) => (
                                    <>
                                      {item.cod_secao ==
                                      subItem.cod_fk_secao ? (
                                        <div
                                          className="additional-item"
                                          key={subIndex + 1}
                                        >
                                          <div className="item-information">
                                            <span className="item-information-title">
                                              {subItem.descricao}
                                            </span>
                                            <br />
                                            <span className="item-information-price">
                                              R${" "}
                                              {parseFloat(
                                                subItem.preco_venda_vista
                                              )?.toFixed(2)}
                                            </span>
                                          </div>
                                          <div className="item-action">
                                            <div className="amount">
                                              <i
                                                onClick={() => [
                                                  !hasIndexies.includes(
                                                    subIndex
                                                  )
                                                    ? ""
                                                    : handleSubAdditionals(
                                                        index,
                                                        subIndex,
                                                        subItem
                                                      ),
                                                ]}
                                                className="fa fa-minus"
                                                style={{
                                                  color: `${
                                                    !hasIndexies.includes(
                                                      subIndex
                                                    )
                                                      ? "gray"
                                                      : ""
                                                  }`,
                                                  opacity: `${
                                                    !hasIndexies.includes(
                                                      subIndex
                                                    )
                                                      ? 0.5
                                                      : ""
                                                  }`,
                                                }}
                                              ></i>

                                              <span
                                                id={`product-${subItem.cod_produto}`}
                                              >
                                                {subItem.quantidade}
                                              </span>

                                              <i
                                                onClick={() => [
                                                  hasIndexies.includes(
                                                    subIndex
                                                  ) ||
                                                  limitSection.includes(index)
                                                    ? ""
                                                    : handleAddAdditionals(
                                                        index,
                                                        subIndex,
                                                        subItem
                                                      ),
                                                ]}
                                                style={{
                                                  color: `${
                                                    hasIndexies.includes(
                                                      subIndex
                                                    ) ||
                                                    limitSection.includes(index)
                                                      ? "gray"
                                                      : ""
                                                  }`,
                                                  opacity: `${
                                                    hasIndexies.includes(
                                                      subIndex
                                                    ) ||
                                                    limitSection.includes(index)
                                                      ? 0.5
                                                      : ""
                                                  }`,
                                                }}
                                                className="fa fa-plus"
                                              ></i>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                                </section>
                              ) : (
                                <section className="additional">
                                  {
                                    <p className="sub-title--additional">
                                      {item.descricao} (Escolha um{" "}
                                      {item.web_quantidade} item)
                                    </p>
                                  }
                                  {item.tb_produtos.map((subItem, subIndex) => (
                                    <>
                                      {item.cod_secao ==
                                      subItem.cod_fk_secao ? (
                                        <div
                                          className="additional-item"
                                          key={subIndex + 1}
                                        >
                                          <div className="item-information">
                                            <span className="item-information-title">
                                              {subItem.descricao}
                                            </span>
                                            <br />
                                            <span className="item-information-price">
                                              R${" "}
                                              {parseFloat(
                                                subItem.preco_venda_vista
                                              )?.toFixed(2)}
                                            </span>
                                          </div>
                                          <div className="item-action">
                                            <input
                                              type="radio"
                                              name={`additional--one-${item.cod_secao}`}
                                              onChange={() =>
                                                handleAdditional(
                                                  index,
                                                  subIndex,
                                                  subItem
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                                </section>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </section>
          <button
            className="save"
            onClick={() => [
              props.setAddiotionals(additionals),
              handleClearFields(),
            ]}
          >
            Salvar
          </button>
        </main>
      </Box>
    </Modal>
  );
};

export default SelectAdditionalModal;
