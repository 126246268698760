/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import "./OrdersList.scss";
import { getOrdersByMenu } from "../../../../components/helpers/Api";
import { Load } from "../../../../components/widgets/spinner/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { } from "react-router-dom";

import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL);

const OrdersList = ({ ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { empresa } = useParams();
  const history = useNavigate();

  socket.on("connect", (res) => {
    console.log("nova conexão?", res);
  });

  useEffect(() => {
    socket.on(
      `update_status${JSON.parse(localStorage.getItem("consumerUser")).telefone
      }${localStorage.getItem("cod_org")}`,
      (res) => {
        console.log(res);
        handleGetOrders(true);
      }
    );
  }, [socket]);

  useEffect(() => {
    handleGetOrders();
  }, []);

  function handleOrderViewDetail(cod_pedidomesa) {
    if (cod_pedidomesa) {
      history(`/menu/${empresa}/pedido/${cod_pedidomesa}`);
    }
  }
  function handleGetOrders(update) {
    if (!update) {
      setLoading(true);
    }
    getOrdersByMenu(
      JSON.parse(localStorage.getItem("consumerUser")).telefone,
      JSON.parse(localStorage.getItem("consumerUser")).id
    )
      .then((res) => {
        setData(res.data.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <main className="menu-orders-list">
        <div className="orders-items">
          <div className="section-one">
            <p className="title">Pedidos</p>
            <button
              onClick={() => history(`/menu/${empresa}`)}
              className="back"
            >
              Voltar
            </button>
          </div>
          {loading ? (
            <Load />
          ) : (
            <>
              {data.length < 1 ? (
                "Não há pedidos"
              ) : (
                <>
                  {data?.map((item) => (
                    <div
                      role="button"
                      onClick={() => [
                        handleOrderViewDetail(item.cod_pedidomesa),
                      ]}
                      className={`order-item ${item.situacao === "finalizado" ? "closed" : ""
                        }`}
                    >
                      <p className="order-number">
                        <span>Pedido #{item.numero_pedidomesa}</span>
                        <span>
                          Status:{" "}
                          <strong
                            className={`${item.situacao === "analisando"
                              ? "analyzing"
                              : item.situacao === "produzindo"
                                ? "producing"
                                : item.situacao === "pronto"
                                  ? "ready"
                                  : ""
                              }`}
                          >
                            {item.situacao}
                          </strong>
                        </span>
                      </p>
                      <p className="order-number">
                        <span>Valor total: {item.valor_total.toFixed(2)}</span>
                        <span></span>
                      </p>
                    </div>
                  ))}
                </>
              )}
            </>
          )}
        </div>
      </main>
    </>
  );
};

export default OrdersList;
