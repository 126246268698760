/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import { getEmpresas, excluirEmpresa } from "../../services/EmpresaService";
import CadEmpresaRow from "./CadEmpresaRow";
import CadEmpresaModal from "./CadEmpresaModal/CadEmpresaModal";

function CadEmpresa() {
  const [empresas, setEmpresas] = useState([]);
  const [count, setCount] = useState(0);
  const defaultLocation = useLocation();
  const [editEmpresa, setEditEmpresa] = useState({
    razao_social: "",
    nome_fantasia: "",
  });
  const token = localStorage.getItem("token");

  function getPage(location) {
    if (!location) {
      location = defaultLocation;
    }

    return new URLSearchParams(location.search).get("page");
  }

  const history = useNavigate();

  useEffect(() => {
    return history.listen((location) => {
      setPage(getPage(location));
    });
  }, [history]);

  const [page, setPage] = useState(getPage());

  useEffect(() => {
    getEmpresas(page || 1, token)
      .then((result) => {
        setEmpresas(result.rows);
        setCount(result.count);
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }, [page]);

  function onAlterarClick(event) {
    const cod_empresa = event.target.id.replace("alterar", "");
    const empresa = empresas.find((e) => e.cod_empresa == cod_empresa);

    setEditEmpresa({ ...empresa });
  }

  function onExcluirClick(event) {
    const cod_empresa = event.target.id.replace("excluir", "");

    excluirEmpresa(cod_empresa, token)
      .then((empresa) => {
        setTimeout(function () {
          history.go(0);
        }, 1000);
      })
      .catch((err) =>
        console.error(err.response ? err.response.data : err.message)
      );
  }

  function onModalSubmit(event) {
    setTimeout(function () {
      history.go(0);
    }, 1000);
  }

  return (
    <React.Fragment>
      <main className="">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          <div className="d-block mb-4 mb-md-0">
            <h2 className="h4">Cadastro Empresa</h2>
          </div>
          <div className="btn-toolbar mb-2 mb-md-0">
            <div className="d-inline-flex align-items-center">
              <button
                id="btnNovaEmpresa"
                className="btn btn-primary animate-up-2"
                data-bs-toggle="modal"
                data-bs-target="#modalEmpresa"
              >
                <svg
                  className="icon icon-xs me-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                Nova Empresa
              </button>
            </div>
          </div>
        </div>
        <div className="card card-body border-0 shadow table-wrapper table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th className="border-gray-200">Código</th>
                <th className="border-gray-200">Razão</th>
                <th className="border-gray-200">Fantasia</th>
                <th className="border-gray-200">CNPJ</th>
                <th className="border-gray-200">Ações</th>
              </tr>
            </thead>
            <tbody>
              {empresas.map((empresa) => (
                <CadEmpresaRow
                  key={empresa.cod_empresa}
                  data={empresa}
                  onAlterarClick={onAlterarClick}
                  onExcluirClick={onExcluirClick}
                />
              ))}
            </tbody>
          </table>
          <Pagination count={count} />
        </div>
      </main>
      <CadEmpresaModal data={editEmpresa} onSubmit={onModalSubmit} />
    </React.Fragment>
  );
}

export default CadEmpresa;
