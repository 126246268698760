/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useEffect, useState } from "react";
import PedidosModal from "./PedidosModal/PedidosModal";
import { getOrders, updateOrderStatus } from "../../components/helpers/Api";
import { Load } from "../../components/widgets/spinner/Spinner";
import { toast } from "react-toastify";

import io from "socket.io-client";

const socket = io(process.env.REACT_APP_API_URL);

const PedidosAbertos = ({ ...props }) => {
  const [orderModal, setOrderModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [orderItems, setOrderItems] = useState({});
  const [idStatus, setIdStatus] = useState("");
  const [producingOrder, setProducingOrder] = useState(0);
  const [analyzingOrder, setAnalyzingOrder] = useState(0);
  const [readyOrder, setReadyOrder] = useState(0);
  const [codMesa, setCodMesa] = useState("");

  useEffect(() => {
    handlegetOrders();
  }, []);
  socket.on("connect", (res) => {
    console.log("nova conexão?", res);
  });

  useEffect(() => {
    socket.on(`new_order${localStorage.getItem("cod_org")}`, (res) => {
      toast.success(res.consumer);
      if (data.length < 1) {
        if (!loading) {
          handlegetOrders(true);
        }
      } else {
        setData((current) => [...current, res.order]);
      }
    });
  }, [socket]);

  function handlegetOrders(newOrder = false) {
    if (!newOrder) {
      setLoading(true);
    }

    getOrders("", 1, null, "ASC")
      .then((res) => {
        console.log(res.data.data.rows);
        setData(res.data.data.rows);
        handleCountAnalyzing(res.data.data.rows);
        handleCountProducing(res.data.data.rows);
        handleCountReady(res.data.data.rows);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function handleCountAnalyzing(array) {
    let count = 0;
    array?.forEach((item) =>
      item.situacao === "analisando" ? (count += 1) : ""
    );
    setAnalyzingOrder(count);
  }
  function handleCountProducing(array) {
    let count = 0;
    array?.forEach((item) =>
      item.situacao === "produzindo" ? (count += 1) : ""
    );
    setProducingOrder(count);
  }
  function handleCountReady(array) {
    let count = 0;
    array?.forEach((item) => (item.situacao === "pronto" ? (count += 1) : ""));
    setReadyOrder(count);
  }
  function handleUpdateStatus(situacao, consumer, cod_pedidomesa) {
    let data = {
      situacao,
      cod_mesa: codMesa,
    };
    if (situacao) {
      updateOrderStatus(idStatus, data)
        .then((res) => {
          handlegetOrders(false);

          if (res) {
            socket.emit(`update_status`, {
              consumer,
              cod_org: localStorage.getItem("cod_org"),
              cod_pedidomesa,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
  }
  return (
    <>
      <React.Fragment>
        <main className="orders-list">
          <p className="title">Meu Pedidos</p>
          <section className="section-one">
            <div className="boxs">
              <div className="input-box">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#5A5A5A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-tag"
                  >
                    <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
                    <line x1="7" y1="7" x2="7.01" y2="7"></line>
                  </svg>
                </span>
                <input type="text" placeholder="Nº Pedido" />
              </div>
              <div className="input-box">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#5A5A5A"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
                <input type="text" placeholder="Buscar pelo cliente" />
              </div>
            </div>
            <div className="box-buttons">
              <button className="save" onClick={() => props.setCreate(null)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-plus"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
                Novo Pedido
              </button>
            </div>
          </section>
          <section className="section-two">
            <div className="order-column" style={{ background: "#c4c4c4" }}>
              <p className="order-state">
                <span>Em análise</span> <span>{analyzingOrder}</span>
              </p>
              {analyzingOrder < 1 ? (
                <p className="order-feed">
                  Nenhum pedido no momento. Compartilhe os seus links nas redes
                  sociais e receba pedidos!
                </p>
              ) : (
                <>
                  {loading ? (
                    <Load />
                  ) : (
                    <div className="order-content">
                      {data?.map((item, index) =>
                        item.situacao === "analisando" ? (
                          <div
                            className="order"
                            key={index}
                            onMouseMove={() => [
                              setCodMesa(item.cod_mesa),
                              setIdStatus(item.cod_pedidomesa),
                            ]}
                          >
                            <div className="order-lines">
                              <h3 className="order-number">
                                Pedido #{item.numero_pedidomesa}
                              </h3>
                              <div className="d-flex">
                                <p className="order-time">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#FFFFFF"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-clock"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <polyline points="12 6 12 12 16 14"></polyline>
                                    </svg>
                                  </span>
                                  <span>
                                    {new Date(item.datahorainicio).getHours() <
                                    10
                                      ? "0" +
                                        new Date(item.datahorainicio).getHours()
                                      : new Date(
                                          item.datahorainicio
                                        ).getHours()}
                                    :
                                    {new Date(
                                      item.datahorainicio
                                    ).getMinutes() < 10
                                      ? "0" +
                                        new Date(
                                          item.datahorainicio
                                        ).getMinutes()
                                      : new Date(
                                          item.datahorainicio
                                        ).getMinutes()}
                                  </span>
                                </p>
                                <p className="view-detail">
                                  {/* <i className="fa-solid fa-eye"></i> */}
                                </p>
                              </div>
                            </div>

                            <div className="order-lines">
                              <div className="order-column-text">
                                <p>{item.nome_cliente}</p>
                                <p>{item.telefone_cliente}</p>
                              </div>
                              <div className="order-column-text">
                                <p>Total: </p>
                                <p>Dinheiro - Troco</p>
                              </div>
                              <div className="order-column-text">
                                <p>R$ {item.valor_total.toFixed(2)}</p>
                                <p>Não Precisa</p>
                              </div>
                            </div>

                            <div
                              className="order-lines"
                              style={{ borderBottom: "none" }}
                            >
                              <div className="order-column-text">
                                <p>
                                  {/* Entrega: sd 3, 627 - pra frente da quadra */}
                                  {item.endereco}
                                </p>
                              </div>
                              {/* <div className="order-column-text">
                                <button>Escolher Entregador</button>
                              </div> */}
                            </div>
                            <button
                              className="back w-100 mb-2"
                              style={{ height: "35px" }}
                              onClick={() => [
                                setOrderItems(item),
                                setOrderModal(true),
                              ]}
                            >
                              Detalhes
                            </button>
                            <button
                              className="save w-100"
                              style={{ height: "35px" }}
                              onClick={() =>
                                handleUpdateStatus(
                                  "produzindo",
                                  item.telefone_cliente,
                                  item.cod_pedidomesa
                                )
                              }
                            >
                              Avançar Pedido
                            </button>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="order-column" style={{ background: "#ffac31" }}>
              <p className="order-state">
                <span>Em Produção</span> <span>{producingOrder}</span>
              </p>

              {producingOrder < 1 ? (
                <p className="order-feed">
                  Nenhum pedido no momento. Compartilhe os seus links nas redes
                  sociais e receba pedidos!
                </p>
              ) : (
                <>
                  {loading ? (
                    <Load />
                  ) : (
                    <div className="order-content">
                      {data?.map((item, index) =>
                        item.situacao === "produzindo" ? (
                          <div
                            className="order"
                            key={index}
                            onMouseMove={() => [
                              setCodMesa(item.cod_mesa),
                              setIdStatus(item.cod_pedidomesa),
                            ]}
                          >
                            <div className="order-lines">
                              <h3 className="order-number">
                                Pedido #{item.numero_pedidomesa}
                              </h3>
                              <div className="d-flex">
                                <p className="order-time">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#FFFFFF"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-clock"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <polyline points="12 6 12 12 16 14"></polyline>
                                    </svg>
                                  </span>
                                  <span>
                                    {new Date(item.datahorainicio).getHours() <
                                    10
                                      ? "0" +
                                        new Date(item.datahorainicio).getHours()
                                      : new Date(
                                          item.datahorainicio
                                        ).getHours()}
                                    :
                                    {new Date(
                                      item.datahorainicio
                                    ).getMinutes() < 10
                                      ? "0" +
                                        new Date(
                                          item.datahorainicio
                                        ).getMinutes()
                                      : new Date(
                                          item.datahorainicio
                                        ).getMinutes()}
                                  </span>
                                </p>
                                <p className="view-detail">
                                  {/* <i className="fa-solid fa-eye"></i> */}
                                </p>
                              </div>
                            </div>

                            <div className="order-lines">
                              <div className="order-column-text">
                                <p>{item.nome_cliente}</p>
                                <p>{item.telefone_cliente}</p>
                              </div>
                              <div className="order-column-text">
                                <p>Total: </p>
                                <p>Dinheiro - Troco</p>
                              </div>
                              <div className="order-column-text">
                                <p>R$ {item.valor_total.toFixed(2)}</p>
                                <p>Não Precisa</p>
                              </div>
                            </div>

                            <div
                              className="order-lines"
                              style={{ borderBottom: "none" }}
                            >
                              <div className="order-column-text">
                                {/* <p>Entrega: sd 3, 627 - pra frente da quadra</p> */}
                                <p>{item.endereco}</p>
                              </div>
                              {/* <div className="order-column-text">
                                <button>Escolher Entregador</button>
                              </div> */}
                            </div>
                            <button
                              className="back w-100 mb-2"
                              style={{ height: "35px" }}
                              onClick={() => [
                                setOrderItems(item),
                                setOrderModal(true),
                              ]}
                            >
                              Detalhes
                            </button>
                            <button
                              className="save w-100"
                              style={{ height: "35px" }}
                              onClick={() =>
                                handleUpdateStatus(
                                  "pronto",
                                  item.telefone_cliente,
                                  item.cod_pedidomesa
                                )
                              }
                            >
                              Avançar Pedido
                            </button>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="order-column" style={{ background: "#5aaf51" }}>
              <p className="order-state">
                <span>
                  Prontos para Entrega{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#FFFFFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-check-circle"
                  >
                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                  </svg>
                </span>{" "}
                <span>{readyOrder}</span>
              </p>

              {readyOrder < 1 ? (
                <p className="order-feed">
                  Nenhum pedido no momento. Compartilhe os seus links nas redes
                  sociais e receba pedidos!
                </p>
              ) : (
                <>
                  {loading ? (
                    <Load />
                  ) : (
                    <div className="order-content">
                      {data?.map((item, index) =>
                        item.situacao === "pronto" ? (
                          <div
                            className="order"
                            key={index}
                            onMouseMove={() => [
                              setCodMesa(item.cod_mesa),
                              setIdStatus(item.cod_pedidomesa),
                            ]}
                          >
                            <div className="order-lines">
                              <h3 className="order-number">
                                Pedido #{item.numero_pedidomesa}
                              </h3>
                              <div className="d-flex">
                                <p className="order-time">
                                  <span>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15px"
                                      height="15px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#FFFFFF"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-clock"
                                    >
                                      <circle cx="12" cy="12" r="10"></circle>
                                      <polyline points="12 6 12 12 16 14"></polyline>
                                    </svg>
                                  </span>
                                  <span>
                                    {new Date(item.datahorainicio).getHours() <
                                    10
                                      ? "0" +
                                        new Date(item.datahorainicio).getHours()
                                      : new Date(
                                          item.datahorainicio
                                        ).getHours()}
                                    :
                                    {new Date(
                                      item.datahorainicio
                                    ).getMinutes() < 10
                                      ? "0" +
                                        new Date(
                                          item.datahorainicio
                                        ).getMinutes()
                                      : new Date(
                                          item.datahorainicio
                                        ).getMinutes()}
                                  </span>
                                </p>
                                {/* <p className="view-detail">
                              <i className="fa-solid fa-eye"></i>
                            </p> */}
                              </div>
                            </div>

                            <div className="order-lines">
                              <div className="order-column-text">
                                <p>{item.nome_cliente}</p>
                                <p>{item.telefone_cliente}</p>
                              </div>
                              <div className="order-column-text">
                                <p>Total: </p>
                                <p>Dinheiro - Troco</p>
                              </div>
                              <div className="order-column-text">
                                <p>R$ {item.valor_total.toFixed(2)}</p>
                                <p>Não Precisa</p>
                              </div>
                            </div>

                            <div
                              className="order-lines"
                              style={{ borderBottom: "none" }}
                            >
                              <div className="order-column-text">
                                {/* <p>Entrega: sd 3, 627 - pra frente da quadra</p> */}
                                <p>{item.endereco}</p>
                              </div>
                              {/* <div className="order-column-text">
                                <button>Escolher Entregador</button>
                              </div> */}
                            </div>
                            <button
                              className="back w-100 mb-2"
                              style={{ height: "35px" }}
                              onClick={() => [
                                setOrderItems(item),
                                setOrderModal(true),
                              ]}
                            >
                              Detalhes
                            </button>
                            <button
                              className="save w-100"
                              style={{ height: "35px" }}
                              onClick={() => props.setClose(item)}
                            >
                              Finalizar Pedido
                            </button>
                          </div>
                        ) : (
                          <></>
                        )
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </section>
          <PedidosModal
            open={orderModal}
            items={orderItems}
            setOrderModal={() => setOrderModal(false)}
            handleUpdateStatus={(status, telefone, cod_pedidomesa) =>
              handleUpdateStatus(status, telefone, cod_pedidomesa)
            }
            onReloadList={() => handlegetOrders()}
            updateOrder={(data) => props.setCreate(data)}
          />
          {/* <OrderItemsModal
            open={openModalDetail}
            setCloseModal={() => setOpenModalDetail(false)}
          /> */}
        </main>
        {/* <PedidosModal data={editParceiro} onSubmit={onModalSubmit} /> */}
      </React.Fragment>
    </>
  );
};

export default PedidosAbertos;
