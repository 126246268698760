/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */

import React, { useRef, useState, useEffect } from 'react';
import { salvarPreVenda } from '../../../services/PreVendaService';
import { salvarItensPreVenda } from '../../../services/ItensPreVendaService';
import { formataData } from "../../../utils/utils";
import { getProdutosPesquisa, getPrecoAVista } from '../../../services/ProdutoService';
import { getItensPreVenda } from '../../../services/ItensPreVendaService';
import { getParceirosPreVenda } from '../../../services/ParceiroService';
import ItensPreVendaRow from "./ItensPreVendaRow";
import { Autocomplete, TextField } from '@mui/material';
import { getVendedores } from '../../../services/FuncionarioService';



function PreVendaModal(props) {

    const [val, setVal] = useState('');
    const [data, setData] = useState([]);

    const date = new Date();

    const [erro, setErro] = useState('');
    const [prevenda, setPreVenda] = useState({
        situacao: 'FECHADO',
        data_fechamento: formataData(date),
        data_emissao: formataData(date),
        cod_pk_funcionario: 1,
        cod_pk_cliente: 1,
        cod_pk_forma_pagto: 6,
        numero_pre_venda: 9999999,
        cod_pk_entregador: 1,
        cod_condicao: 1,
        cod_pk_troca: 1,
        cod_pk_docorig: 1,
        cod_usuario: 1,
        cod_pk_empresa: 1,
        valor_bruto: 0,
        desconto: 0,
        acrescimo: 0,
        valor_liquido: 0
    });

    const [itensPreVenda, setItensPreVenda] = useState([]);

    const [itemPreVenda, setItemPreVenda] = useState({
        cod_pk_produto: 1,
        quantidade: 0,
        desconto: 0
    });
    const [parceiros, setParceiros] = useState([]);
    const [inputValueParceiros, setInputValueParceiros] = useState('');
    const [optionsParceiros, setOptionsParceiros] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [inputValueProdutos, setInputValueProdutos] = useState('');
    const [optionsProdutos, setOptionsProdutos] = useState([]);
    const [produtoEscolhido, setProdutoEscolhido] = useState({});
    const [parceiroEscolhido, setParceiroEscolhido] = useState({});
    const [vendedorEscolhido, setVendedorEscolhido] = useState({});
    const [vendedores, setVendedores] = useState([]);
    const [inputValueVendedores, setInputValueVendedores] = useState('');
    const [optionsVendedores, setOptionsVendedores] = useState([]);
    const [formaPagamento, setFormaPagamento] = useState({ nome: "dinheiro", codigo: 1 });
    const [pagamentos, setPagamentos] = useState([]);
    const [valorUnitarioPagamento, setValorUnitarioPagamento] = useState(0);
    const [produtosInseridos, setProdutosInseridos] = useState([]);
    const [valorSemDesconto, setvalorSemDesconto] = useState(0);
    const [descontoTotal, setDescontoTotal] = useState(0);
    const [subtotal, setSubtotal] = useState(0);
    const [desconto, setDesconto] = useState(0);
    const btnClose = useRef('');
    const btnSalvar = useRef('');
    const inputValor_unitario = useRef('');
    const inputDesconto_unitario = useRef('');
    const inputQuantidade = useRef('');
    const inputDescricao = useRef('');
    const inputPesquisa = useRef('');
    const [codPrevenda, setCodPreVenda] = useState(null);
    const token = localStorage.getItem('token');
    const cod_org = localStorage.getItem('cod_org');

    useEffect(() => {
        setPreVenda(props.data);
    }, [props.data])

    function onInputChange(event) {
        setPreVenda(prevState => ({ ...prevState, [event.target.id]: event.target.value }));
    };

    async function FinalizarNota() {
        try {
            const dataFechamento = formataData(date);
            const dataEmissao = formataData(date);

            const preVendaData = {
                situacao: 'FECHADO',
                data_fechamento: dataFechamento,
                data_emissao: dataEmissao,
                cod_pk_funcionario: vendedorEscolhido.cod_funcionario,
                cod_pk_cliente: parceiroEscolhido.cod_parceiro,
                cod_pk_forma_pagto: formaPagamento.codigo,
                numero_pre_venda: 9999999,
                cod_pk_entregador: 1,
                cod_condicao: 1,
                cod_pk_troca: 1,
                cod_pk_docorig: 1,
                cod_usuario: 1,
                cod_pk_empresa: 1,
                valor_bruto: valorSemDesconto,
                desconto: descontoTotal,
                acrescimo: 0,
                valor_liquido: subtotal
            };

            const cod_prevenda = await salvarPreVenda(codPrevenda, preVendaData, token);
            console.log(cod_prevenda)
            const novosItens = produtosInseridos.map((produto) => ({
                cod_org,
                cod_pk_pre_venda: cod_prevenda,
                cod_pk_produto: produto.cod_produto,
                cod_pk_empresa: prevenda.cod_pk_empresa,
                cod_pk_mecanico: 1,
                descricao: produto.nome,
                quantidade: produto.quantidade,
                desconto_unitario: produto.desconto,
                valor_unitario: produto.preco,
                valor_total_unitario: produto.subtotal
            }));

            await salvarItensPreVenda(codPrevenda, novosItens, token);

            setPreVenda(preVendaData);
            setItensPreVenda(prevItens => [...prevItens, ...novosItens]);
        } catch (error) {
            console.error("Error finalizing note:", error);
        }
    };


    const handelProdutosAutocmplete = async () => {
        const produtosPesquisa = await getProdutosPesquisa(cod_org, token);
        setProdutos(produtosPesquisa);
    };

    const handleParceiro = async () => {
        const token = localStorage.getItem("token");
        const cod_org = localStorage.getItem("cod_org");
        const parceirosPrevenda = await getParceirosPreVenda(cod_org, token);
        setParceiros(parceirosPrevenda);
    };

    const getVendedoresAutocomplete = async () => {
        const vendendoresAutocomplete = await getVendedores(cod_org, token)
        setVendedores(vendendoresAutocomplete);
    };

    const handleInputChangeParceiros = (event, newInputValue) => {

        setInputValueParceiros(newInputValue);

        const filteredOptions = parceiros?.filter(parceiro =>
            parceiro.razao_social.toLowerCase().includes(newInputValue.toLowerCase()) ||
            parceiro.cod_parceiro.toString().includes(newInputValue)
        );
        console.log(filteredOptions)
        setOptionsParceiros(filteredOptions);
    };

    const handleInputChangeProdutos = (event, newInputValue) => {

        setInputValueProdutos(newInputValue);

        const filteredOptions = produtos?.filter(produto =>
            produto.descricao.toLowerCase().includes(newInputValue.toLowerCase()) ||
            produto.cod_produto.toString().includes(newInputValue)
        );
        setOptionsProdutos(filteredOptions);

    };

    const handleInputChangeVendedor = (event, newInputValue) => {

        setInputValueVendedores(newInputValue);

        const filteredOptions = vendedores?.filter(vendedor =>
            vendedor.nome_funcionario.toLowerCase().includes(newInputValue.toLowerCase()) ||
            vendedor.cod_funcionario.toString().includes(newInputValue)
        );

        setOptionsVendedores(filteredOptions);
    };


    const incluirPagamento = () => {
        const pagamentoIncluido = { nome: formaPagamento.nome, codigo: formaPagamento.codigo, valor: valorUnitarioPagamento };

        setPagamentos(prevPagamento => ([
            ...prevPagamento,
            pagamentoIncluido
        ]
        ));
    };

    useEffect(() => {
        handelProdutosAutocmplete()
    }, []);

    const insereProduto = () => {
        const produtosAInserir = {
            cod_pre_venda: prevenda.cod_pre_venda,
            nome: produtoEscolhido.descricao,
            cod_produto: produtoEscolhido.cod_produto,
            quantidade: itemPreVenda.quantidade,
            preco: produtoEscolhido.preco_venda_vista,
            desconto: itemPreVenda.desconto,
            subtotal: (1 - 0.01 * itemPreVenda.desconto) * itemPreVenda.quantidade * produtoEscolhido.preco_venda_vista
        };

        setProdutosInseridos(prevProdutosInseridos => {
            const novosProdutos = [...prevProdutosInseridos, produtosAInserir];
            const novoValorSemDesconto = novosProdutos.reduce((total, produto) => total + produto.preco * produto.quantidade, 0);
            const novoSubtotal = novosProdutos.reduce((total, produto) => total + produto.subtotal, 0);
            const descontoTotal = (1 - (novoSubtotal / novoValorSemDesconto)) * 100;

            setvalorSemDesconto(novoValorSemDesconto);
            setSubtotal(novoSubtotal);
            setDescontoTotal(descontoTotal);

            return novosProdutos;
        });
    };

    const excluirProdutoLinha = (index) => {
        setProdutosInseridos(prevProdutosInseridos => {
            const novosProdutos = [...prevProdutosInseridos];
            const produtoExcluido = novosProdutos.splice(index, 1)[0];
            const novoValorSemDesconto = novosProdutos.reduce((total, produto) => total + produto.preco * produto.quantidade, 0);
            const novoSubtotal = novosProdutos.reduce((total, produto) => total + produto.subtotal, 0);
            const descontoTotal = (1 - (novoSubtotal / novoValorSemDesconto)) * 100;

            setvalorSemDesconto(novoValorSemDesconto);
            setSubtotal(novoSubtotal);
            setDescontoTotal(descontoTotal);

            return novosProdutos;
        });
    };


    const handleQuantidadeChange = (e) => {
        const quantidade = e.target.value ? parseFloat(e.target.value) : 0;
        setItemPreVenda(prev => ({ ...prev, quantidade }));
    };

    const handleDescontoChange = (e) => {
        const desconto = e.target.value ? parseFloat(e.target.value) : 0;
        setItemPreVenda(prev => ({ ...prev, desconto }));
    };

    return (

        <div className="modal fade" id="modalPreVenda" tabIndex="-1" role="dialog" aria-labelledby="modalTitleNotify" aria-hidden="true">
            <div className="modal-dialog modal-xl" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <p className="modal-title" id="modalTitleNotify">{props.data.cod_mesa ? 'Alterar ' : 'Novo '}Pedido</p>
                        <button ref={btnClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="close"></button>
                    </div>
                    <div className="modal-body form-control-sm">
                        <div className="row border" >
                            <div className="col-md-12"  >
                                <div className="row mb-2">
                                    <div className="col-sm-3">
                                        <label htmlFor="descricao" align='Left' >Nº Pedido</label>
                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled />
                                    </div>
                                    <div className="col-md-2">
                                        <label htmlFor="tipo" align='Left' >Tipo</label>
                                        <select className="form-select form-select-sm" value={prevenda.situacao} name="situacao" id="situacao" onChange={onInputChange} >
                                            <option value='P'>Pedido</option>
                                            <option value='O'>Orçamento</option>
                                        </select>
                                    </div>
                                    <div className="col-sm-2">
                                        <label htmlFor="data_emissao" align='Left' >Emissão</label>
                                        <input className="form-control form-control-sm" id="data_emissao" type="text" value={prevenda.data_emissao} disabled />
                                    </div>
                                    <div className="col-sm-2">
                                        <label htmlFor="data_fechamento" align='Left' >Fechamento</label>
                                        <input className="form-control form-control-sm" id="data_fechamento" type="text" value={prevenda.data_fechamento} disabled />
                                    </div>
                                    <div className="col-md-3">
                                        <label htmlFor="tipo" align='Left' >Situacao</label>
                                        <select className="form-select form-select-sm" value={prevenda.situacao} name="situacao" id="situacao" onChange={onInputChange} >
                                            <option value='FECHADO'>Fechado</option>
                                            <option value='ABERTO'>Aberto</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row border" >
                            <div className="col-sm-8 border"  >
                                <nav>
                                    <div className="nav nav-tabs form-control form-control-sm row" id="nav-tab" role="tablist">
                                        <button className="nav-link col-md-3 active" id="nav-produto-tab" data-bs-toggle="tab" data-bs-target="#nav-produto" type="button" role="tab" aria-controls="nav-produto" aria-selected="false">Produto</button>
                                        <button className="nav-link col-md-3" id="nav-parceiro-tab" data-bs-toggle="tab" data-bs-target="#nav-parceiro" type="button" role="tab" aria-controls="nav-parceiro" aria-selected="false" onClick={handleParceiro}>Parceiro</button>
                                        <button className="nav-link col-md-3" id="nav-vendedor-tab" data-bs-toggle="tab" data-bs-target="#nav-vendedor" type="button" role="tab" aria-controls="nav-vendedor" aria-selected="false" onClick={getVendedoresAutocomplete}>Vendedor</button>
                                        <button className="nav-link col-md-3" id="nav-vendedor-tab" data-bs-toggle="tab" data-bs-target="#nav-pagamento" type="button" role="tab" aria-controls="nav-pagamento" aria-selected="false">Pagamento</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-produto" role="tabpanel" aria-labelledby="nav-produto-tab">
                                        <div className="row" >
                                            <div className="col-md-3 mb-1 mt-2">
                                                <label htmlFor="pesquisaProduto" align='Left' >Pesquisa</label>
                                                <Autocomplete
                                                    freeSolo
                                                    value={produtoEscolhido}
                                                    onChange={(event, newValue) => {
                                                        setProdutoEscolhido(newValue)
                                                    }}
                                                    inputValue={inputValueProdutos}
                                                    onInputChange={handleInputChangeProdutos}
                                                    options={produtos}
                                                    getOptionLabel={(option) => `${option.cod_produto} - ${option.descricao}`}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Buscar por Nome ou codigo" variant="outlined" fullWidth />
                                                    )}
                                                />
                                                <datalist id="data">
                                                    {data.map((op) => <option key={op}>{op}</option>)}
                                                </datalist>
                                            </div>
                                            <div className="col-sm-2 mt-2">
                                                <label htmlFor="cod_pk_produto" align='Left' >Código</label>
                                                <input className="form-control form-control-sm" id="cod_pk_produto" value={produtoEscolhido?.cod_produto} type="text" disabled />
                                            </div>
                                            <div className="col-sm-7 mt-2">
                                                <label htmlFor="descricao" align='Left' >Descrição do Produto</label>
                                                <input ref={inputDescricao} className="form-control form-control-sm" id="descricao" type="text" value={produtoEscolhido?.descricao} disabled />
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="quantidade" align='Left' >Quantidade</label>
                                                <input ref={inputQuantidade} className="form-control form-control-sm" id="quantidade" onChange={handleQuantidadeChange} type="number" placeholder="0,00" />
                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="desconto_unitario" align='Left' >Descontos</label>
                                                <input ref={inputDesconto_unitario} className="form-control form-control-sm" id="descricao" type="number" onChange={handleDescontoChange} placeholder="0,00" />
                                            </div>
                                            <div className="col-sm-4 mt-2">
                                                <label htmlFor="valor_unitario" align='Left' >Valor Unitário</label>
                                                <input ref={inputValor_unitario} className="form-control form-control-sm" id="valor_unitario" value={produtoEscolhido?.preco_venda_vista} type="number" placeholder="0,00" />
                                            </div>
                                        </div>
                                        <div className="col-sm-3 mb-3">
                                            <button onClick={insereProduto} type="button" className="btn btn-sm btn-success col-md-12" > Inserir
                                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" fill="currentColor" className="bi bi-arrow-down-square" viewBox="0 0 16 16" onClick={"a"}>
                                                    <path onClick={"a"} fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show" id="nav-parceiro" role="tabpanel" aria-labelledby="nav-parceiro-tab">
                                        <div className="row">
                                            <div className="col-md-12 mb-1">
                                                <br />
                                                <Autocomplete
                                                    freeSolo
                                                    value={parceiroEscolhido}
                                                    onChange={(event, newValue) => {
                                                        setParceiroEscolhido(newValue);
                                                    }}
                                                    inputValue={inputValueParceiros}
                                                    onInputChange={handleInputChangeParceiros}
                                                    options={parceiros}
                                                    getOptionLabel={(option) => option ? `${option.cod_parceiro} - ${option.razao_social}` : ''}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Buscar por Nome ou código"
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                    )}
                                                />
                                                <hr></hr>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-sm-6">
                                                <label htmlFor="descricao" align='Left' >Razao Social</label>
                                                <input className="form-control form-control-sm" id="descricao" value={parceiroEscolhido?.razao_social} type="text" disabled />
                                            </div>
                                            <div className="col-sm-6">
                                                <label htmlFor="descricao" align='Left' >CPF / CNPJ</label>
                                                <input className="form-control form-control-sm" id="descricao" type="text" disabled value={parceiroEscolhido?.cnpj} />
                                            </div>
                                        </div>
                                        <p>
                                            <a className="link-primary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                + Dados
                                            </a>
                                        </p>
                                        <div className="collapse" id="collapseExample">
                                            <div className="card card-body">
                                                <div className="row mb-2">
                                                    <div className="col-sm-7">
                                                        <label htmlFor="descricao" align='Left' >Endereço</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled value={parceiroEscolhido?.logradouro} />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <label htmlFor="descricao" align='Left' >Numero</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled value={parceiroEscolhido.numero} />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label htmlFor="descricao" align='Left' >Bairro</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled value={parceiroEscolhido.bairro} />
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col-sm-2">
                                                        <label htmlFor="descricao" align='Left' >UF</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled value={parceiroEscolhido?.uf} />
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <label htmlFor="descricao" align='Left' >Cidade</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled value={parceiroEscolhido?.cidade} />
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <label htmlFor="descricao" align='Left' >Inscr. Estadual</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-vendedor" role="tabpanel" aria-labelledby="nav-vendedor-tab">
                                        <div className="row">
                                            <div className="col-md-12 mb-1">
                                                <br />
                                                <Autocomplete
                                                    freeSolo
                                                    value={vendedorEscolhido}
                                                    onChange={(event, newValue) => {
                                                        setVendedorEscolhido(newValue)
                                                    }}
                                                    inputValue={inputValueVendedores}
                                                    onInputChange={handleInputChangeVendedor}
                                                    options={vendedores}
                                                    getOptionLabel={(option) => `${option.cod_funcionario} - ${option.nome_funcionario}`}
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Buscar por Nome ou codigo" variant="outlined" fullWidth />
                                                    )}
                                                />                                                <hr></hr>
                                            </div>
                                        </div>
                                        <div className="row mb-2">
                                            <div className="col-sm-6">
                                                <label htmlFor="descricao" align='Left' >Nome Vendedor</label>
                                                <input className="form-control form-control-sm" id="descricao" value={vendedorEscolhido?.nome_funcionario} type="text" disabled />
                                            </div>
                                            <div className="col-sm-6">
                                                <label htmlFor="descricao" align='Left' >Comissão</label>
                                                <input className="form-control form-control-sm" id="descricao" type="text" value={vendedorEscolhido?.comissao_vista} disabled />
                                            </div>
                                        </div>
                                        <p>
                                            <a className="link-primary" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                + Dados
                                            </a>
                                        </p>
                                        <div className="collapse" id="collapseExample">
                                            <div className="card card-body">

                                                <div className="row mb-2">
                                                    <div className="col-sm-7">
                                                        <label htmlFor="descricao" align='Left' >Endereço</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" value={vendedorEscolhido?.logradouro} disabled />
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <label htmlFor="descricao" align='Left' >Numero</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" value={vendedorEscolhido?.numero} disabled />
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <label htmlFor="descricao" align='Left' >Bairro</label>
                                                        <input className="form-control form-control-sm" id="descricao" type="text" value={vendedorEscolhido?.bairro} disabled />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade show" id="nav-pagamento" role="tabpanel" aria-labelledby="nav-pagamento-tab">
                                        <div className="row mb-1">
                                            <div className="col-md-6">
                                                <label htmlFor="tipo" align='Left' >Forma de pagamento</label>
                                                <select className="form-select form-select-sm" name="situacao" id="situacao" onChange={(e) => setFormaPagamento(JSON.parse(e.target.value))} >
                                                    <option value={JSON.stringify({ nome: 'Dinheiro', codigo: 1 })}>Dinheiro</option>
                                                    <option value={JSON.stringify({ nome: 'Cartão Debito', codigo: 2 })}>Cartão Debito</option>
                                                    <option value={JSON.stringify({ nome: 'Cartão Credito', codigo: 3 })}>Cartão Credito</option>
                                                    <option value={JSON.stringify({ nome: 'Crediario', codigo: 4 })}>Crediario</option>
                                                    <option value={JSON.stringify({ nome: 'Cheque', codigo: 5 })}>Cheque</option>
                                                    <option value={JSON.stringify({ nome: 'Pix/Transferencias', codigo: 6 })}>Pix/Transferencias</option>
                                                </select>
                                            </div>
                                            <div className="col-sm-3">
                                                <label htmlFor="valor" align='Left' >Valor Unitário</label>
                                                <input className="form-control form-control-sm" id="valor" onChange={(e) => setValorUnitarioPagamento(e.target.value)} type="number" placeholder="0,00" />
                                            </div>
                                            <div className="col-sm-3">
                                                <br></br>

                                                <button type="button" className="btn btn-sm btn-success col-md-12" onClick={incluirPagamento} > Incluir
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="currentColor" className="bi bi-arrow-down-square" viewBox="0 0 16 16">
                                                        <path fillRule="evenodd" d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                            <table className="table table-striped table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Codigo</th>
                                                        <th scope="col">Forma de Pagamento</th>
                                                        <th scope="col">Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {pagamentos &&
                                                        pagamentos.map(pagamento => (

                                                            <tr>
                                                                <td>{pagamento.codigo}</td>
                                                                <td>{pagamento.nome}</td>
                                                                <td>{pagamento.valor}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 border"  >
                                <div className="row">
                                    <hr></hr>
                                    <div className="col-sm-6">
                                        <label htmlFor="descricao" align='Left' >Sub. Total</label>
                                        <input className="form-control form-control-sm" id="descricao" type="number" value={valorSemDesconto} placeholder="0,00" />
                                    </div>
                                    <div className="col-sm-6">
                                        <label htmlFor="descricao" align='Left' >Descontos</label>
                                        <input className="form-control form-control-sm" id="descricao" type="number" value={descontoTotal} placeholder="0,00" />
                                    </div>
                                    <div className="col-sm-12 center">
                                        <br></br>
                                        <label htmlFor="descricao" align='Left' >Valor Total</label>
                                        <input className="form-control" id="descricao" type="text" value={subtotal} placeholder="0,00" />
                                    </div>
                                </div>
                                <br></br>
                                <hr></hr>
                                <div className="row md-12">
                                    <div className="col-sm-1">

                                    </div>
                                    <button id="btnCancelar" className="btn btn-danger col-sm-5" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                                        </svg> Cancelar
                                    </button>
                                    <div className="col-sm-1">

                                    </div>
                                    <button id="btnFinalizar" className="btn btn-info col-sm-5" onClick={FinalizarNota}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="25" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                        </svg> Finalizar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="row border" >
                            <div className="col-sm-12 col-md-12 col-lg-12"  >
                                <div className="row border">
                                    <div className="table-wrapper-scroll-y my-custom-scrollbar">
                                        <br></br>
                                        <table className="table table-bordered table-striped mb-2">
                                            <thead className="table-success">
                                                <tr>
                                                    <th >Nome do Produto</th>
                                                    <th >Qtde</th>
                                                    <th >Preço un.</th>
                                                    <th >Descontos</th>
                                                    <th >Sub. Total</th>
                                                    <th >Ações</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    produtosInseridos &&
                                                    produtosInseridos.map((produto, index) => (
                                                        <tr key={produto.cod_produto}>
                                                            <td>{produto.nome}</td>
                                                            <td>{produto.quantidade}</td>
                                                            <td>{produto.preco}</td>
                                                            <td>{produto.desconto}</td>
                                                            <td>{produto.subtotal}</td>
                                                            <td>
                                                                <button
                                                                    id={"excluir" + produto.cod_produto}
                                                                    type="button"
                                                                    className="btn btn-danger btn-xs ms-2"
                                                                    title={`Excluir ${produto.nome}`}
                                                                    onClick={() => excluirProdutoLinha(index)}
                                                                >
                                                                    <svg
                                                                        className="icon icon-xs"
                                                                        fill="currentColor"
                                                                        viewBox="0 0 20 20"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <path
                                                                            fillRule="evenodd"
                                                                            d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                                                            clipRule="evenodd"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row border" >
                            <div className="col-sm-2"  >

                            </div>
                            <div className="col-sm-2"  >

                            </div>
                            <div className="col-sm-2"  >

                            </div>
                            <div className="col-sm-2"  >

                            </div>
                            <div className="col-sm-2"  >

                            </div>
                            <div className="col-sm-2">
                                <button id="btnImprimir" className="btn btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="30" fill="currentColor" className="bi bi-printer-fill" viewBox="0 0 16 16">
                                        <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
                                        <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
                                    </svg> Imprimir
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default PreVendaModal;