import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "../public/Login/Login";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth" element={<Navigate to="/auth/login" />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
};

export default AuthRoutes;
